import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { forEach, random } from 'lodash'

import { Layout, Block, Header, MetaTags } from '../components'

import logo from '../assets/images/logo.svg'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import themes, { getThemeData } from '../styles/themes';
import { heading } from '../styles/type';
import { media } from '../styles/utils';
import { getRandomIndex } from '../utils';


class Finalists extends React.Component {

	state = {
		activeHoverSlug: true,
		hoverImages: []
	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressHome.acf_json),
            globalData: JSON.parse(this.props.data.wordpressGlobal.acf_json),
			categories: this.props.data.allWordpressCategories.edges,
		})
	}

	componentDidMount() {
		this.setState({
			hoverImages: this.randomiseImages()
		})
	}

	randomiseImages = () => {
		const { categories } = this.state;

		let images = [];

		categories.map((edge, i) => {
			const cat = edge.node;
			const catFields = JSON.parse(edge.node.acf_json);

			let previousAlignment = getRandomIndex(['left', 'right']);
			const verticalSpacing = 20;
	
			catFields.images.map((image, i) => {
				previousAlignment = previousAlignment == 'left' ? 'right' : 'left';

				image.align = previousAlignment == 'left' ? 'left' : 'right',
				image.transformX = `${random(0, 10)}%`,
				image.transformY = `${random(0, 10)}%`,
				image.slug = cat.slug;
				image.top = `${i == 0 ? verticalSpacing : (i + 1) * verticalSpacing }%`

				images.push(image);
			})
		})

		return images
	}

	renderHoverImages = () => {
		const { activeHoverSlug, hoverImages } = this.state;

		return hoverImages.map((image, i) => {
			return (
				<Image
					active={activeHoverSlug == image.slug}
					src={image.image.sizes && image.image.sizes.medium2}
					style={{
						top: image.top,
						left: image.align == 'left' && image.transformX,
						right: image.align == 'right' && image.transformX,
					}}
					key={i}
				/>		
			)
		})
	}

	renderHeroCategories = () => {
		const { categories } = this.state;

		return categories.map((edge, i) => {
			const cat = edge.node;
			const catFields = JSON.parse(edge.node.acf_json);

			if (catFields) {
				return (
					<Category
						key={i}
						theme={{
							text: catFields.text_colour,
							background: catFields.background_colour
						}}
						layout={catFields.layout}
						onClick={() => navigate(`/finalists/${cat.slug}`)}
						onMouseEnter={() => this.setState({activeHoverSlug: cat.slug})}
						onMouseLeave={() => this.setState({activeHoverSlug: null})}
					>
						{cat.title}
					</Category>
				)
			}
		})
	}

	renderHero = () => {
		return (
			<Hero>
				<Container>
                    <Heading>Finalists</Heading>
					{this.renderHeroCategories()}
					{this.renderHoverImages()}
				</Container>
			</Hero>
		)
	}


	render() {       
		const { data, globalData } = this.state;

        let meta_description = data.meta_description;
        if(!meta_description) meta_description = globalData.meta_description_default;

		return (
			<Layout
				// Replace with term slug
				theme={'residential-architecture'}
				footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>
                <MetaTags
                    title={'Home'}
                    description={meta_description}
                />
				<Wrapper>

                    <Header
						showNav={true}
						showSubmit={true}
						fixed={true}
						theme={{
							colour: brown,
							background: grey
						}}
					/>

					{this.renderHero()} 
	

				</Wrapper>
			</Layout>
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
`

const Heading = styled.div`
	max-width: 100%;
	text-align: center;
	${heading};
	color: #54421A;
	margin: 190px 0;
	text-transform: uppercase;

`

// Hero

const Hero = styled.div`
	background: ${grey};
	padding: 100px 0 170px;
	width: 100%;
	max-width: 100vw;
	overflow: hidden;
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;


	${Container} {
		align-items: center;
	}

	${media.phone`
		padding: 150px 0 60px;
	`}
`




const Logo = styled.div`
	background-image: url(${logo});
	max-width: 450px;
	width: 100%;
	height: 280px;
	${bgIcon}
	margin-bottom: 120px;
	z-index: 3;

	${media.phone`
		max-width: 308px;
		height: 195px;
		margin-bottom: 68px;
	`}
`

// Hero Cateogories

const Image = styled.img`
	width: auto;
	height: 315px;
	position: absolute;
	z-index: 1;
	padding: relative;
	opacity: ${props => props.active ? 1 : 0};
	transition: opacity 0.6s ease;

	${media.phone`
		display: none;
	`}
`

const Category = styled.div`
	font-size: 30px;
	letter-spacing: 0.027em;
	color: ${brown};
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	transition: color 0.2s ease;
	z-index: 2;
	padding: relative;

	&:not(:last-of-type) {
		margin-bottom: 30px;

		${media.phone`
			margin-bottom: 25px;
		`}
	}

	/* Hover Theme */

	${props => {
		if (props.theme) return css`
			&:hover {
				color: ${props.theme.text};

				${Image} {
					opacity: 1;
				}
			}
		`
	}}

	/* Hover Images */

	${props => {
		if (props.layout) return css`
			${Image} {

				&[data-index="0"] {
					top: 233px;
					right: 15px;
				}

				&[data-index="1"] {
					top: 20%;
					left: 167px;
				}

				&[data-index="2"] {
					bottom: 130px;
					left: 30px;
				}
			}
		`
	}}

	${media.phone`
		font-size: 20px;
	`}
`




export const query = graphql`
	query {
		wordpressHome {
			acf_json	
		}	

        wordpressGlobal {
			acf_json	
		}	

		allWordpressCategories {
			edges {
				node {
					title
					slug
					acf_json
				}
			}
		}
	}
`

export default Finalists

